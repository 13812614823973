[hidden] {
  /* stylelint-disable-next-line declaration-no-important */
  display: none !important;
}

html {
  /*
   * For more information on dynamic font scaling, visit the documentation:
   * https://ionicframework.com/docs/layout/dynamic-font-scaling
   */
  --ion-dynamic-font: var(--ion-default-dynamic-font);
  --ion-font-family: Arial, sans-serif;
}

.container {
  max-width:200px;
  background:rgba(255, 255, 255, 1);
  margin:40px auto;
  padding:10px 0px 20px 0px;
  border:1px solid #FFF;
  border-radius:4px;
  box-shadow:0px 4px 5px rgba(255, 255, 255, 1);
}

.link {
  font-size:20px;
  font-weight:300;
  text-align:center;
  position:relative;
  height:50px;
  line-height:40px;
  overflow:hidden;
  width:90%;
  margin-left:3%;
  cursor:pointer;
}

.link:after {
  content: '';
  position:absolute;
  width:80%;
  border-bottom:1px solid rgba(255, 255, 255, 0.5);
  bottom:50%;
  left:-100%;
  transition-delay: all 0.5s;
  transition: all 0.5s;
}

.link:hover:after,
.link.hover:after {
  left:100%;
}

.link .text {
  text-shadow:0px -40px 0px rgba(0, 0, 0, 1);
  transition:all 0.75s;
  transform:translateY(100%) translateZ(0);
  transition-delay:all 0.25s;
}

.link:hover .text,
.link.hover .text {
  text-shadow:0px -40px 0px rgba(255, 255, 255, 0);
  transform:translateY(0%) translateZ(0) scale(1.1);
  font-weight:600;
}
